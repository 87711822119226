// import lazySizes from 'lazysizes';
// import '//cdnjs.cloudflare.com/ajax/libs/dom4/2.0.0/dom4.js';
// import '//cdnjs.cloudflare.com/ajax/libs/bluebird/3.3.5/bluebird.min.js';
import 'promise-polyfill/src/polyfill';
// App main
const main = async () => {
    //Import our CSS
    const styles = await import(/* webpackChunkName: "styles" */ '../css/app.pcss');
    // const styles = await import(/* webpackChunkName: "styles" */ '../css/app.scss');
    const lazySizes = await import(/* webpackChunkName: "lazySizes" */ 'lazysizes');
    await import('lazysizes/plugins/bgset/ls.bgset');
    //Async load the vue module
    const Vue = await import(/* webpackChunkName: "vue" */ 'vue');
    // Create our vue instance
    if (document.querySelector('#app')) {
        const vm = new Vue.default({
            el: '#app',
            components: {
                sanksjonskart: () =>
                    import(/* webpackChunkName: "sanksjonskart" */ '../vue/Sanksjonskart.vue')
            },
            data: {},
            methods: {},
            mounted() {}
        });
    }
};
// Execute async function

/* jshint esversion: 6 */

var _s = (selector, context) => {
    var d = context || document;
    return Array.apply(null, d.querySelectorAll(selector));
};

var _si = (selector, context, returnNull) => {
    var d = context || document;
    var tmp = d.querySelector(selector);
    return tmp ? tmp : returnNull ? null : document.createElement('div');
};

var _ael = (selector, ev, callback) => {
    var elm = typeof selector === 'string' ? _si(selector) : selector;
    elm.addEventListener(ev, callback);
};

var _ajax = (url, callback) => {
    var request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    request.onload = () => {
        if (request.status >= 200 && request.status < 400) {
            callback(request);
        } else {
            console.error('request error', request);
        }
    };

    request.onerror = err => {
        console.error('error', err);
    };

    request.send();
};

var _parseHTML = str => {
    var tmp = document.implementation.createHTMLDocument('tmp');
    tmp.body.innerHTML = str;
    return tmp.body.children;
};

main().then(value => {
    'use strict';

    var today = new Date(),
        hour,
        min,
        sec,
        hands = {
            hour: _si('.time'),
            min: _si('.min'),
            sec: _si('.sek')
        },
        list = _si('.arrangement-liste'),
        hasList = _s('.arrangement-liste').length > 0,
        gradients = _s('.face .gradient'),
        dagensArrangement = [],
        articleCount = 0,
        maxArticles = 2,
        listTimer = 0,
        listShouldMove = false,
        hasSynced = false,
        firstZero = true,
        isFemte = location.href.indexOf('/femte') > -1;

    function sync() {
        if (hasSynced) {
            return;
        }
        hasSynced = true;
        listTimer = performance.now();
        listShouldMove = _s('article:not(.idag)').length > maxArticles;
    }

    function render() {
        today = new Date();
        hour = today.getHours();
        min = today.getMinutes();
        sec = today.getSeconds();
        var ms = today.getMilliseconds();

        if (hasSynced) {
            if (listShouldMove && performance.now() - listTimer > 12000) {
                listTimer = performance.now();
                moveList();
            }
        }

        if (hour % 2 === 0 && min === 0 && sec === 0) {
            location.reload();
        }

        if (sec === 0) {
            hands.sec.style.transitionDuration = '0s';
            if (firstZero) {
                var td = new Date();
                gradients.forEach(function(g) {
                    g.style.opacity = 0;
                });
                dagensArrangement.forEach(arr => {
                    var arrH = arr.split(':')[0];
                    var arrM = arr.split(':')[1];
                    td.setHours(arrH, arrM, 0);
                    var diff = Math.ceil((td.getTime() - new Date().getTime()) / 1000 / 60);
                    if (diff <= 15 && diff > 0) {
                        _si('.g_' + diff).style.opacity = 1;
                        _si('.g_' + diff).style.webkitTransform = 'rotate(' + arrM * 6 + 'deg)';
                    }
                });
                firstZero = false;
            }
        } else if (sec === 10) {
            sync();
        } else {
            hands.sec.style.transitionDuration = '';
            firstZero = true;
        }

        hands.hour.style.webkitTransform = 'rotate(' + (hour * 30 + min / 2) + 'deg)';
        hands.min.style.webkitTransform = 'rotate(' + min * 6 + 'deg)';
        hands.sec.style.webkitTransform = 'rotate(' + sec * 6 + 'deg)';

        requestAnimationFrame(render);
    }
    render();

    function populateList(data) {
        var elms = _parseHTML(data.responseText);
        if ((!hasList && elms.length > 1) || (hasList && elms.length === 1)) {
            _si('.arrangement').innerHTML = data.responseText;
            list = _si('.arrangement-liste');
            hasList = !hasList;
        }

        if (elms.length > 1) {
            var inner = elms[1].innerHTML;
            dagensArrangement = [];
            var doc = document.implementation.createHTMLDocument('tmp');
            doc.body.innerHTML = inner;
            [].map.call(doc.getElementsByTagName('script'), function(el) {
                dagensArrangement.push(el.textContent);
            });

            list.innerHTML = inner;

            articleCount = _s('article').length;
            listShouldMove = articleCount > maxArticles;
            if (!listShouldMove) {
                setTimeout(refreshList, 60 * 1000);
            }

            setTimeout(() => {
                var height = 0;
                var articles = _s('article:not(.idag)', list);
                if (listShouldMove) {
                    articles.forEach(elm => {
                        elm.style.height = elm.getBoundingClientRect().height + 'px';
                    });
                }
            }, 2000);
        } else {
            setTimeout(refreshList, 60 * 1000);
        }
    }

    function refreshList() {
        if (isFemte) {
            _ajax('/infoskjerm/sections/arrangement/femte', data => {
                var elms = _parseHTML(data.responseText);
                if (elms.length === 1) {
                    _ajax('/infoskjerm/sections/arrangement', populateList);
                    document.documentElement.classList.remove('femte');
                } else {
                    populateList(data);
                    document.documentElement.classList.add('femte');
                }
            });
        } else {
            _ajax('/infoskjerm/sections/arrangement', populateList);
        }
    }

    function articleTransitionEnd() {
        var article = _si('article.out');
        _si('.arrangement-liste').appendChild(article);
        //article.classList.remove('out');
        article.removeEventListener('transitionend', articleTransitionEnd);
    }

    function moveList() {
        var article = _si('article:not(.idag)');
        if (article.classList.contains('out')) {
            article = _si('article:not(.idag)');
        }
        if (article.classList.contains('l-' + articleCount)) {
            setTimeout(refreshList, 200);
        }
        article.classList.add('out');
        article.addEventListener('transitionend', articleTransitionEnd);
    }

    refreshList();
});
